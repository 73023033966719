import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`msc`, `insurance`, `products`]} />
    <section
      css={{
        marginBottom: '5em',
        padding: '1.5em 0',
        textAlign: 'center'
      }}
    >
      <h1
        css={{
          textAlign: 'center',
          lineHeight: '1.125',
          maxWidth: '14em',
          margin: '0 auto',
          marginBottom: '1.5em',
          fontSize: '42px',
          fontWeight: 400
        }}
      >
        Grow your business by offering a superior and comprehensive
        product through our platform.
      </h1>
      <p css={{ marginBottom: '0.5em' }}>Uncomplicate the complicated?</p>
      <a
        href="mailto:office@msc.insure"
        css={{
          display: 'inline-block',
          textDecoration: 'none',
          fontFamily: 'Alegreya Sans',
          fontWeight: 500,
          fontSize: '24px',
          appearance: 'none',
          background: '#000',
          borderRadius: '0.125em',
          border: 'none',
          padding: '0.375em 1em',
          color: '#fff'
        }}
      >
        Join Us
      </a>
    </section>
    <section css={{ marginBottom: '5em' }}>
      <h2
        css={{
          fontVariantCaps: 'all-small-caps',
          fontWeight: 600,
          fontSize: 18,
          textTransform: 'lowercase',
          letterSpacing: '0.04em'
        }}
      >
        About Us
      </h2>
      <p css={{ fontSize: 24 }}>
        We were founded as a humanistic alternative to conventional insurance praxis.
        Insurance can be difficult to navigate, and we're here to change that. As
        a small company we are able to cater and tailor our services to the
        individuals and companies that need them. We work with our clients to curate
        a highly personalized product by using technology as a vehicle to minimize
        mundane administrative tasks and focus on what's most important to all of us
        overall output of value. For us, human interaction is key—as is having a
        simple and straightforward relationship from start to finish.
      </p>
    </section>
    <section>
    </section>
    <section>
      <h2
        css={{
          fontVariantCaps: 'all-small-caps',
          fontWeight: 600,
          fontSize: 18,
          textTransform: 'lowercase',
          letterSpacing: '0.04em'
        }}
      >
        Core Team
      </h2>
      <div
        css={{
          display: 'grid',
          gridGap: '2em',
          gridTemplateColumns: '1fr',
          '@media only screen and (min-width: 600px)': {
            gridTemplateColumns: 'repeat(2,1fr)'
          }
        }}
      >
        <div css={{ paddingLeft: '1em', borderLeft: '4px solid' }}>
          <div css={{ fontSize: 21, fontWeight: 'bold' }}>Philip Machat</div>
          <div>Business Development</div>
        </div>
        <div css={{ paddingLeft: '1em', borderLeft: '4px solid' }}>
          <p css={{ fontSize: 21, fontWeight: 'bold' }}>Günter Glück</p>
          <div>Software Architecture</div>
        </div>
        <div css={{ paddingLeft: '1em', borderLeft: '4px solid' }}>
          <p css={{ fontSize: 21, fontWeight: 'bold' }}>
            Ferdinand Salis-Samaden
          </p>
          <div>Product Design</div>
        </div>
        <div css={{ paddingLeft: '1em', borderLeft: '4px solid' }}>
          <p css={{ fontSize: 21, fontWeight: 'bold' }}>
            Johann Salis-Samaden
          </p>
          <div>Customer Relations</div>
        </div>
        <div css={{ paddingLeft: '1em', borderLeft: '4px solid' }}>
          <p css={{ fontSize: 21, fontWeight: 'bold' }}>Emefa Ama Kludze</p>
          <div>Research &amp; Content</div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
